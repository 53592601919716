import styled from '@emotion/styled';
import { readableColorIsBlack } from 'color2k';
import { ifProp, switchProp } from 'styled-tools';
import { TextVariants, TextShade } from '.';

type StyledTextProps = {
    variant?: TextVariants;
    shade?: TextShade;
    lineClamp?: number;
};

export const StyledText = styled.p<StyledTextProps>(
    ({ theme, shade = 'paragraph' }) => ({
        // Default ('Body Regular' variant in the design)
        color: theme.text.color[shade],
        fontSize: theme.text.variants.body,
        fontFamily: theme.fonts.body,
        lineHeight: theme.lineHeights.base,
        letterSpacing: theme.letterSpacings.xs,

        // Base selection on textShade
        '&::selection': {
            background: readableColorIsBlack(theme.text.color[shade])
                ? theme.highlight.backgroundInverted
                : theme.highlight.background,
            color: readableColorIsBlack(theme.text.color[shade])
                ? theme.highlight.colorInverted
                : theme.highlight.color,
        },

        img: {
            maxWidth: '100%',
            height: 'auto',
        },
    }),
    ifProp('lineClamp', ({ lineClamp }) => ({
        display: '-webkit-box',
        '-webkit-line-clamp': `${lineClamp}`,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    })),
    switchProp('as', {
        i: {
            fontStyle: 'italic',
        },
        em: {
            fontStyle: 'italic',
        },
        strong: ({ theme }) => ({
            fontWeight: theme.text.weights.strong,
        }),
        b: ({ theme }) => ({
            fontWeight: theme.text.weights.bold,
        }),
        mono: () => ({
            fontFamily: 'monospace',
        }),
    }),
    switchProp('variant', {
        display1: ({ theme, shade = 'headline' }) => ({
            color: theme.text.color[shade],
            fontFamily: theme.fonts.headline,
            fontSize: theme.text.variants.display1,
            lineHeight: theme.lineHeights.headline,
            letterSpacing: theme.letterSpacingsFluid.md,
        }),
        display2: ({ theme, shade = 'headline' }) => ({
            color: theme.text.color[shade],
            fontFamily: theme.fonts.headline,
            fontSize: theme.text.variants.display2,
            lineHeight: theme.lineHeights.headline,
            letterSpacing: theme.letterSpacingsFluid.md,
        }),
        display3: ({ theme, shade = 'headline' }) => ({
            color: theme.text.color[shade],
            fontFamily: theme.fonts.headline,
            fontSize: theme.text.variants.display3,
            lineHeight: theme.lineHeights.headline,
            letterSpacing: theme.letterSpacingsFluid.md,
        }),
        display4: ({ theme, shade = 'headline' }) => ({
            color: theme.text.color[shade],
            fontFamily: theme.fonts.headline,
            fontSize: theme.text.variants.display4,
            lineHeight: theme.lineHeights.headline,
            letterSpacing: theme.letterSpacingsFluid.md,
        }),
        display5: ({ theme, shade = 'headline' }) => ({
            color: theme.text.color[shade],
            fontFamily: theme.fonts.headline,
            fontSize: theme.text.variants.display5,
            lineHeight: theme.lineHeights.headline,
            letterSpacing: 0,
        }),
        display6: ({ theme, shade = 'headline' }) => ({
            color: theme.text.color[shade],
            fontFamily: theme.fonts.headline,
            fontSize: theme.text.variants.display6,
            lineHeight: theme.lineHeights.headline,
            letterSpacing: 0,
        }),
        caption: ({ theme, shade = 'headline' }) => ({
            color: theme.text.color[shade],
            fontSize: theme.text.variants.caption,
            lineHeight: theme.fontSizes.sm,
            letterSpacing: theme.letterSpacings.xs,
        }),
        overline: ({ theme, shade = 'headline' }) => ({
            color: theme.text.color[shade],
            fontSize: theme.text.variants.overline,
            lineHeight: theme.fontSizes.sm,
            letterSpacing: theme.letterSpacings.xs,
            textTransform: 'uppercase',
        }),
        quote: ({ theme }) => ({
            fontSize: theme.text.variants.quote,
            lineHeight: theme.fontSizes['4xl'],
            letterSpacing: 0,
        }),
        link: ({ theme }) => ({
            fontSize: theme.text.variants.link,
            letterSpacing: 0,
            textDecoration: 'underline',
        }),
        bodySm: ({ theme }) => ({
            fontSize: theme.text.variants.bodySm,
            lineHeight: theme.lineHeights.base,
            letterSpacing: theme.letterSpacings.xs,
        }),
    })
);
